import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { Row, Col, Space, Divider } from 'antd';
import getConfig from 'next/config';
import { FacebookIcon, LinkedInIcon, YouTubeIcon } from 'components/Icons';
import Section from 'components/Section';
import Image from 'next/image';
import Logo from 'public/static/images/logo.png';
import RavenLogo from 'public/static/images/raven.svg';
import KFZLogo from 'public/static/images/kfz-betrieb.png';
import VogelLogo from 'public/static/images/vogel.svg';

const FooterContainer = styled(Row)`
    background-color: ${(props) => props.theme.colors.grey};
    padding-bottom: 20px;
`;

const LogotypeImage = styled(Image)`
    filter: grayscale(100%);
    margin: 15px;
`;

const FooterMenu = styled.div`
    a {
        color: ${({ theme }) => theme.colors.greyishBrown};
    }
    font-weight: 300;
`;

const AboutText = styled.div`
    color: ${({ theme }) => theme.colors.greyishBrown};
    font-weight: 300;
    p {
        margin: 0;
    }
`;

export const Footer = (): JSX.Element => {
    const { socials, name } = getConfig().publicRuntimeConfig;
    return (
        <FooterContainer justify="center">
            <Col span={20}>
                <footer>
                    <Row justify="center">
                        <Col style={{ padding: 12 }}>
                            <Space wrap>
                                {socials.fb && (
                                    <a
                                        href={socials.fb}
                                        title={`Odwiedź profil ${name} na Facebook-u`}
                                        rel="me nofollow noreferrer"
                                        target="_blank"
                                    >
                                        <FacebookIcon style={{ color: '#464646' }} />
                                    </a>
                                )}
                                {socials.ln && (
                                    <a
                                        href={socials.ln}
                                        title={`Odwiedź profil ${name} na LinkedIn`}
                                        rel="me nofollow noreferrer"
                                        target="_blank"
                                    >
                                        <LinkedInIcon style={{ color: '#464646' }} />
                                    </a>
                                )}
                                {socials.yt && (
                                    <a
                                        href={socials.yt}
                                        title={`Odwiedź profil ${name} na YouTube`}
                                        rel="me nofollow noreferrer"
                                        target="_blank"
                                    >
                                        <YouTubeIcon style={{ color: '#464646' }} />
                                    </a>
                                )}
                            </Space>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <FooterMenu>
                                <Space
                                    wrap
                                    align="center"
                                    split={
                                        <Divider
                                            type="vertical"
                                            style={{
                                                borderLeft: '1px solid rgba(85, 85, 85, 0.6)'
                                            }}
                                        />
                                    }
                                    size="small"
                                    style={{
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Link href="/o-tytule">O tytule</Link>
                                    <Link href="/reklama">Reklama</Link>
                                    <Link href="/prenumerata">Prenumerata</Link>
                                    <Link href="/kontakt">Kontakt</Link>
                                    <Link href="/archiwum">Archiwum</Link>
                                    <Link href="/regulamin">Regulamin serwisu</Link>
                                    <Link href="/polityka-prywatnosci">Polityka prywatności</Link>
                                    <Link href="/about-the-title">EN</Link>
                                    <Link href="/uber-den-titel">DE</Link>
                                </Space>
                            </FooterMenu>
                        </Col>
                    </Row>
                    <Section justify="center">
                        <Col span={20}>
                            <Row gutter={[24, 32]}>
                                <Col sm={12} md={8}>
                                    <LogotypeImage
                                        width={70}
                                        height={40}
                                        src={Logo}
                                        alt={name}
                                        style={{
                                            objectFit: 'contain',
                                            height: 'auto'
                                        }}
                                    />
                                    <AboutText>
                                        <p>Redakcje i biura: ul. Strzegomska 42AB 53-611 Wrocław</p>
                                        <p>tel. 71 7823 180</p>
                                        <p>autoexpert@autoexpert.pl</p>
                                    </AboutText>
                                </Col>
                                <Col sm={12} md={8}>
                                    <Image
                                        width={144}
                                        height={49}
                                        src={RavenLogo}
                                        alt="Raven Media"
                                    />
                                    <AboutText>
                                        Wydawca: Raven Media sp. z o.o. NIP 897-17-67-168 REGON
                                        021366963 Organ Rejestrowy: Sąd Rejonowy dla Wrocławia
                                        Fabrycznej VI Wydział Gospodarczy KRS 0000370285
                                    </AboutText>
                                </Col>
                                <Col sm={24} md={8}>
                                    <Row gutter={24} align="middle" style={{ height: 49 }}>
                                        <Col span={12}>
                                            <Image
                                                width={106}
                                                height={17}
                                                src={KFZLogo}
                                                alt="KFZ Batrieb Logotype"
                                                style={{
                                                    objectFit: 'contain',
                                                    maxWidth: '100%',
                                                    height: 'auto'
                                                }}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Image
                                                width={150}
                                                height={33}
                                                src={VogelLogo}
                                                alt="Vogel"
                                                style={{
                                                    objectFit: 'contain',
                                                    maxWidth: '100%',
                                                    height: 'auto'
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <AboutText>
                                        Licencja: The Polish edition of &quot;kfz-betrieb&quot; is a
                                        publication of Raven Media sp. z o.o. licensed by Vogel
                                        Communications Group GmbH & Co. KG, 97082 Wurzburg/Germany.
                                    </AboutText>
                                </Col>
                            </Row>
                        </Col>
                    </Section>
                </footer>
            </Col>
        </FooterContainer>
    );
};

export default Footer;
